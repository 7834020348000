export default {
    "name": "ImageSKUMinimalData",
    "kind": "HoudiniQuery",
    "hash": "34829dec11c658ca3519b768ff3cd4b1dfe02688ad2003817c56496bced8a0d5",

    "raw": `query ImageSKUMinimalData($handle: String, $country: CountryCode!, $language: LanguageCode!) @inContext(country: $country, language: $language) {
  product(handle: $handle) {
    id
    title
    description
    collections(first: 100) {
      edges {
        node {
          title
          handle
          description
          path: metafield(key: "path", namespace: "sprenger") {
            key
            value
            id
          }
          id
        }
      }
    }
    variants(first: 100) {
      edges {
        node {
          id
          title
          sku: metafield(key: "just_sku", namespace: "sprenger") {
            key
            value
            id
          }
          image_urls: metafield(key: "image_urls", namespace: "sprenger") {
            key
            value
            id
          }
          isArchived: metafield(key: "is_archived", namespace: "sprenger") {
            key
            value
            id
          }
          quantityAvailable
          priceV2 {
            amount
            currencyCode
          }
          compareAtPriceV2 {
            amount
            currencyCode
          }
          unitPrice {
            amount
            currencyCode
          }
          unitPriceMeasurement {
            referenceValue
            referenceUnit
          }
          bundle_desc: metafield(key: "bundle_desc", namespace: "sprenger") {
            key
            value
            id
          }
        }
      }
    }
    priceRange {
      maxVariantPrice {
        amount
        currencyCode
      }
      minVariantPrice {
        amount
        currencyCode
      }
    }
    compareAtPriceRange {
      maxVariantPrice {
        amount
        currencyCode
      }
      minVariantPrice {
        amount
        currencyCode
      }
    }
  }
}
`,

    "rootType": "QueryRoot",

    "selection": {
        "fields": {
            "product": {
                "type": "Product",
                "keyRaw": "product(handle: $handle)",
                "nullable": true,

                "selection": {
                    "fields": {
                        "id": {
                            "type": "ID",
                            "keyRaw": "id",
                            "visible": true
                        },

                        "title": {
                            "type": "String",
                            "keyRaw": "title",
                            "visible": true
                        },

                        "description": {
                            "type": "String",
                            "keyRaw": "description",
                            "visible": true
                        },

                        "collections": {
                            "type": "CollectionConnection",
                            "keyRaw": "collections(first: 100)",

                            "selection": {
                                "fields": {
                                    "edges": {
                                        "type": "CollectionEdge",
                                        "keyRaw": "edges",

                                        "selection": {
                                            "fields": {
                                                "node": {
                                                    "type": "Collection",
                                                    "keyRaw": "node",

                                                    "selection": {
                                                        "fields": {
                                                            "title": {
                                                                "type": "String",
                                                                "keyRaw": "title",
                                                                "visible": true
                                                            },

                                                            "handle": {
                                                                "type": "String",
                                                                "keyRaw": "handle",
                                                                "visible": true
                                                            },

                                                            "description": {
                                                                "type": "String",
                                                                "keyRaw": "description",
                                                                "visible": true
                                                            },

                                                            "path": {
                                                                "type": "Metafield",
                                                                "keyRaw": "path(key: \"path\", namespace: \"sprenger\")",
                                                                "nullable": true,

                                                                "selection": {
                                                                    "fields": {
                                                                        "key": {
                                                                            "type": "String",
                                                                            "keyRaw": "key",
                                                                            "visible": true
                                                                        },

                                                                        "value": {
                                                                            "type": "String",
                                                                            "keyRaw": "value",
                                                                            "visible": true
                                                                        },

                                                                        "id": {
                                                                            "type": "ID",
                                                                            "keyRaw": "id",
                                                                            "visible": true
                                                                        }
                                                                    }
                                                                },

                                                                "visible": true
                                                            },

                                                            "id": {
                                                                "type": "ID",
                                                                "keyRaw": "id",
                                                                "visible": true
                                                            }
                                                        }
                                                    },

                                                    "visible": true
                                                }
                                            }
                                        },

                                        "visible": true
                                    }
                                }
                            },

                            "visible": true
                        },

                        "variants": {
                            "type": "ProductVariantConnection",
                            "keyRaw": "variants(first: 100)",

                            "selection": {
                                "fields": {
                                    "edges": {
                                        "type": "ProductVariantEdge",
                                        "keyRaw": "edges",

                                        "selection": {
                                            "fields": {
                                                "node": {
                                                    "type": "ProductVariant",
                                                    "keyRaw": "node",

                                                    "selection": {
                                                        "fields": {
                                                            "id": {
                                                                "type": "ID",
                                                                "keyRaw": "id",
                                                                "visible": true
                                                            },

                                                            "title": {
                                                                "type": "String",
                                                                "keyRaw": "title",
                                                                "visible": true
                                                            },

                                                            "sku": {
                                                                "type": "Metafield",
                                                                "keyRaw": "sku(key: \"just_sku\", namespace: \"sprenger\")",
                                                                "nullable": true,

                                                                "selection": {
                                                                    "fields": {
                                                                        "key": {
                                                                            "type": "String",
                                                                            "keyRaw": "key",
                                                                            "visible": true
                                                                        },

                                                                        "value": {
                                                                            "type": "String",
                                                                            "keyRaw": "value",
                                                                            "visible": true
                                                                        },

                                                                        "id": {
                                                                            "type": "ID",
                                                                            "keyRaw": "id",
                                                                            "visible": true
                                                                        }
                                                                    }
                                                                },

                                                                "visible": true
                                                            },

                                                            "image_urls": {
                                                                "type": "Metafield",
                                                                "keyRaw": "image_urls(key: \"image_urls\", namespace: \"sprenger\")",
                                                                "nullable": true,

                                                                "selection": {
                                                                    "fields": {
                                                                        "key": {
                                                                            "type": "String",
                                                                            "keyRaw": "key",
                                                                            "visible": true
                                                                        },

                                                                        "value": {
                                                                            "type": "String",
                                                                            "keyRaw": "value",
                                                                            "visible": true
                                                                        },

                                                                        "id": {
                                                                            "type": "ID",
                                                                            "keyRaw": "id",
                                                                            "visible": true
                                                                        }
                                                                    }
                                                                },

                                                                "visible": true
                                                            },

                                                            "isArchived": {
                                                                "type": "Metafield",
                                                                "keyRaw": "isArchived(key: \"is_archived\", namespace: \"sprenger\")",
                                                                "nullable": true,

                                                                "selection": {
                                                                    "fields": {
                                                                        "key": {
                                                                            "type": "String",
                                                                            "keyRaw": "key",
                                                                            "visible": true
                                                                        },

                                                                        "value": {
                                                                            "type": "String",
                                                                            "keyRaw": "value",
                                                                            "visible": true
                                                                        },

                                                                        "id": {
                                                                            "type": "ID",
                                                                            "keyRaw": "id",
                                                                            "visible": true
                                                                        }
                                                                    }
                                                                },

                                                                "visible": true
                                                            },

                                                            "quantityAvailable": {
                                                                "type": "Int",
                                                                "keyRaw": "quantityAvailable",
                                                                "nullable": true,
                                                                "visible": true
                                                            },

                                                            "priceV2": {
                                                                "type": "MoneyV2",
                                                                "keyRaw": "priceV2",

                                                                "selection": {
                                                                    "fields": {
                                                                        "amount": {
                                                                            "type": "Decimal",
                                                                            "keyRaw": "amount",
                                                                            "visible": true
                                                                        },

                                                                        "currencyCode": {
                                                                            "type": "CurrencyCode",
                                                                            "keyRaw": "currencyCode",
                                                                            "visible": true
                                                                        }
                                                                    }
                                                                },

                                                                "visible": true
                                                            },

                                                            "compareAtPriceV2": {
                                                                "type": "MoneyV2",
                                                                "keyRaw": "compareAtPriceV2",
                                                                "nullable": true,

                                                                "selection": {
                                                                    "fields": {
                                                                        "amount": {
                                                                            "type": "Decimal",
                                                                            "keyRaw": "amount",
                                                                            "visible": true
                                                                        },

                                                                        "currencyCode": {
                                                                            "type": "CurrencyCode",
                                                                            "keyRaw": "currencyCode",
                                                                            "visible": true
                                                                        }
                                                                    }
                                                                },

                                                                "visible": true
                                                            },

                                                            "unitPrice": {
                                                                "type": "MoneyV2",
                                                                "keyRaw": "unitPrice",
                                                                "nullable": true,

                                                                "selection": {
                                                                    "fields": {
                                                                        "amount": {
                                                                            "type": "Decimal",
                                                                            "keyRaw": "amount",
                                                                            "visible": true
                                                                        },

                                                                        "currencyCode": {
                                                                            "type": "CurrencyCode",
                                                                            "keyRaw": "currencyCode",
                                                                            "visible": true
                                                                        }
                                                                    }
                                                                },

                                                                "visible": true
                                                            },

                                                            "unitPriceMeasurement": {
                                                                "type": "UnitPriceMeasurement",
                                                                "keyRaw": "unitPriceMeasurement",
                                                                "nullable": true,

                                                                "selection": {
                                                                    "fields": {
                                                                        "referenceValue": {
                                                                            "type": "Int",
                                                                            "keyRaw": "referenceValue",
                                                                            "visible": true
                                                                        },

                                                                        "referenceUnit": {
                                                                            "type": "UnitPriceMeasurementMeasuredUnit",
                                                                            "keyRaw": "referenceUnit",
                                                                            "nullable": true,
                                                                            "visible": true
                                                                        }
                                                                    }
                                                                },

                                                                "visible": true
                                                            },

                                                            "bundle_desc": {
                                                                "type": "Metafield",
                                                                "keyRaw": "bundle_desc(key: \"bundle_desc\", namespace: \"sprenger\")",
                                                                "nullable": true,

                                                                "selection": {
                                                                    "fields": {
                                                                        "key": {
                                                                            "type": "String",
                                                                            "keyRaw": "key",
                                                                            "visible": true
                                                                        },

                                                                        "value": {
                                                                            "type": "String",
                                                                            "keyRaw": "value",
                                                                            "visible": true
                                                                        },

                                                                        "id": {
                                                                            "type": "ID",
                                                                            "keyRaw": "id",
                                                                            "visible": true
                                                                        }
                                                                    }
                                                                },

                                                                "visible": true
                                                            }
                                                        }
                                                    },

                                                    "visible": true
                                                }
                                            }
                                        },

                                        "visible": true
                                    }
                                }
                            },

                            "visible": true
                        },

                        "priceRange": {
                            "type": "ProductPriceRange",
                            "keyRaw": "priceRange",

                            "selection": {
                                "fields": {
                                    "maxVariantPrice": {
                                        "type": "MoneyV2",
                                        "keyRaw": "maxVariantPrice",

                                        "selection": {
                                            "fields": {
                                                "amount": {
                                                    "type": "Decimal",
                                                    "keyRaw": "amount",
                                                    "visible": true
                                                },

                                                "currencyCode": {
                                                    "type": "CurrencyCode",
                                                    "keyRaw": "currencyCode",
                                                    "visible": true
                                                }
                                            }
                                        },

                                        "visible": true
                                    },

                                    "minVariantPrice": {
                                        "type": "MoneyV2",
                                        "keyRaw": "minVariantPrice",

                                        "selection": {
                                            "fields": {
                                                "amount": {
                                                    "type": "Decimal",
                                                    "keyRaw": "amount",
                                                    "visible": true
                                                },

                                                "currencyCode": {
                                                    "type": "CurrencyCode",
                                                    "keyRaw": "currencyCode",
                                                    "visible": true
                                                }
                                            }
                                        },

                                        "visible": true
                                    }
                                }
                            },

                            "visible": true
                        },

                        "compareAtPriceRange": {
                            "type": "ProductPriceRange",
                            "keyRaw": "compareAtPriceRange",

                            "selection": {
                                "fields": {
                                    "maxVariantPrice": {
                                        "type": "MoneyV2",
                                        "keyRaw": "maxVariantPrice",

                                        "selection": {
                                            "fields": {
                                                "amount": {
                                                    "type": "Decimal",
                                                    "keyRaw": "amount",
                                                    "visible": true
                                                },

                                                "currencyCode": {
                                                    "type": "CurrencyCode",
                                                    "keyRaw": "currencyCode",
                                                    "visible": true
                                                }
                                            }
                                        },

                                        "visible": true
                                    },

                                    "minVariantPrice": {
                                        "type": "MoneyV2",
                                        "keyRaw": "minVariantPrice",

                                        "selection": {
                                            "fields": {
                                                "amount": {
                                                    "type": "Decimal",
                                                    "keyRaw": "amount",
                                                    "visible": true
                                                },

                                                "currencyCode": {
                                                    "type": "CurrencyCode",
                                                    "keyRaw": "currencyCode",
                                                    "visible": true
                                                }
                                            }
                                        },

                                        "visible": true
                                    }
                                }
                            },

                            "visible": true
                        }
                    }
                },

                "visible": true
            }
        }
    },

    "pluginData": {
        "houdini-svelte": {}
    },

    "input": {
        "fields": {
            "handle": "String",
            "country": "CountryCode",
            "language": "LanguageCode"
        },

        "types": {}
    },

    "policy": "CacheOrNetwork",
    "partial": false
};

"HoudiniHash=88bc875700ec665c9153cde07101166bd011f0404f56cd2ea72640d709490b3f";