<script lang="ts">
	import { ImageSKUMinimalDataStore } from '$houdini';
	import { languageStore } from '$lib/store/language.store';
	import { getProductPath } from '$lib/utils/productPath';
	import UnitPrice from '$lib/components/Product/UnitPrice.svelte';
	import ProductPriceRange from '$lib/components/Product/ProductPriceRange.svelte';
	import { activeShopTheLookPoint } from '$lib/store/activeShopTheLookPoint.store';
	import { createBreakpointStore } from '$lib/store/breakpoint.store';
	import { mapToNode } from '$lib/utils/product';
	import { ensureCountry, ensureLocale } from '$lib/utils/i18n';
	import { onVisible } from '$lib/utils/intersection';
	import { isNetworkError } from '$lib/utils';

	const store = new ImageSKUMinimalDataStore();

	export let productSKU: string;
	export let x: number;
	export let y: number;

	$: trySplitted = (() => {
		const divisors = ['/', '|', '?='];
		for (const divisor of divisors) {
			const splitted = productSKU.split(divisor);
			if (splitted.length > 1) {
				return splitted;
			}
		}
		return [productSKU];
	})();

	$: variant =
		trySplitted.length > 1
			? mapToNode($store.data?.product?.variants).find(
					(variant) => variant.sku?.value === (trySplitted[1] || undefined)
				)
			: mapToNode($store.data?.product?.variants)[0] || undefined;

	$: showDetails = $activeShopTheLookPoint === productSKU;
	const isMobile = createBreakpointStore();
	let innerWidth = 0;
	$: percentFromStart = x / innerWidth;
	$: percentFromEnd = 1 - percentFromStart;
</script>

<svelte:window bind:innerWidth />

<div
	use:onVisible={() => {
		store
			.fetch({
				variables: {
					handle: trySplitted[0],
					country: ensureCountry($languageStore.country),
					language: ensureLocale($languageStore.locale)
				}
			})
			.catch((e) => {
				if (isNetworkError(e)) {
					return;
				}
				throw e;
			});
	}}
	style="top: {y}px; left: {x}px;"
	class="absolute"
>
	<div class="relative">
		{#if $store.data?.product}
			<a
				class="absolute z-20 bottom-4 left-0 transform md:-translate-x-1/2"
				style:transform={$isMobile
					? percentFromStart > 0.4
						? `translateX(-${percentFromEnd * 100}%)`
						: 'translateX(-10%)'
					: ''}
				class:hidden={!showDetails}
				href={getProductPath(trySplitted[0], $store.data.product.title)}
			>
				<div
					class="p-4 gap-2 text-left items-start flex flex-col min-w-[300px] max-w-[300px] min-h-[135px] bg-white border-dark-quaternary border rounded-md shadow-l3"
				>
					<span class="line-clamp-2 text-h-16 text-dark-primary">
						{$store.data?.product.title}
						{variant ? ` - ${variant.title}` : ''}
					</span>
					<span class="line-clamp-2 text-p-14 text-dark-secondary">
						{$store.data?.product.description}
					</span>
					{#if variant}
						<UnitPrice
							price={variant.priceV2}
							measurement={variant.unitPriceMeasurement}
							measurementPrice={variant.unitPrice}
							referenceUnit={variant.bundle_desc?.value}
							class="!text-h-14 !text-accent-primary !font-bold flex-col"
						/>
					{:else}
						<ProductPriceRange
							shouldWrap={false}
							class="!text-h-14 !text-accent-primary !font-bold flex-col"
							priceRange={$store.data.product.priceRange}
							compareAtPriceRange={$store.data.product.compareAtPriceRange}
						/>
					{/if}
				</div>
			</a>
		{/if}
	</div>
	<button
		on:click|stopPropagation={() => {
			activeShopTheLookPoint.set(productSKU);
		}}
		on:mouseenter={() => {
			activeShopTheLookPoint.set(productSKU);
		}}
		class="rounded-full w-6 h-6 bg-[#e8e8ea] focus:scale-110 hover:scale-110 transition-transform items-center flex justify-center"
	>
		<div class="w-3 h-3 rounded-full bg-white"></div>
	</button>
</div>
